import { AuthProvider } from '@/types';
import * as SocialIcons from '@/components/presentational/tailwind/Auth/Icons';

function capitalize(word: string) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const RenderProviders = ({
  providers,
  onProviderLoginRequested,
  isLoading,
  isLogin = true,
}: {
  providers: AuthProvider[];
  onProviderLoginRequested: (provider: AuthProvider) => void;
  isLoading: boolean;
  isLogin?: boolean;
}) => {
  return (
    <div className="space-y-2">
      {providers.map((provider) => {
        const AuthIcon = SocialIcons[provider];
        return (
          <button
            className="flex items-center justify-center w-full space-x-2 text-sm font-medium transition-all duration-300 border rounded-full h-9 border-neutral-300 hover:shadow"
            disabled={isLoading}
            onClick={() => onProviderLoginRequested(provider)}
            key={provider}
          >
            {/* Set brightness to 2 on group hover */}
            <AuthIcon className="self-center w-5 h-5" />
            {isLogin ? (
              <span className="self-center">
                Log in with {capitalize(provider)}
              </span>
            ) : (
              <span className="self-center">
                Sign up with {capitalize(provider)}
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
};
