import { AlertCircle } from "@/components/Icons";
import React, { useState } from "react";

const InvalidLoginError = ({ errorMessage }: { errorMessage?: string }) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="bg-red-light flex items-center text-[13px] font-medium border border-red/20 rounded-lg space-x-2 py-2 px-2.5 mt-8">
      <AlertCircle />
      <p className="text-red" dangerouslySetInnerHTML={{ __html: errorMessage || "Invalid login credentials" }}></p>
      <button type="button" className="!ml-auto" onClick={() => setIsVisible(false)}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="transition-all duration-300 hover:saturate-150 hover:contrast-150 hover:brightness-75">
          <g opacity="0.32">
            <path
              d="M11.3334 4.6665L4.66675 11.3332M4.66675 4.6665L11.3334 11.3332"
              stroke="#D8211C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </button>
    </div>
  );
};

export default InvalidLoginError;
